import React from "react";
import { Card, CardText } from "react-md";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing";
import SEO from "../components/SEO";
import config from "../../data/SiteConfig";
import "./index.scss";
import DownloadCard from "../components/LeadMagnet";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: true
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (window.innerWidth >= 640) {
      this.setState({ mobile: false });
    } else {
      this.setState({ mobile: true });
    }
  }

  render() {
    const { mobile } = this.state;
    const postOverlapClass = mobile ? "post-overlap-mobile" : "post-overlap";
    const postEdges = this.props.data.allMdx.edges;
    return (
      <Layout location={this.props.location} title="Home">
        <div className="index-container">
          <Helmet>
            <title>{config.siteTitle}</title>
            <link rel="canonical" href={`${config.siteUrl}`} />
          </Helmet>
          <SEO postEdges={postEdges} />
          <div
            className={`md-grid md-cell--9 post-page-contents mobile-fix ${postOverlapClass}`}
          >
            <Card className="md-grid md-cell md-cell--12 post">
              <CardText className="post-body">

                <p>
                Learning to play piano is a tough thing to take up, especially if you want to get really good, really fast. There are several ways you can learn piano, including some that are quite expensive and others which are free. This site is dedicated to getting you started on the right track with many free online piano lessons as well as some helpful reviews of online piano courses.
                </p>
                <p>
                To get started I would recommend you take a look through our <strong><a href="/learn-piano-basics">Piano Basics</a></strong> so that you can get a grasp on many of the basic fundamentals of playing piano, including how to read sheet music, proper piano posture, what the notes on the piano are and other important fundamentals.
                </p>
                <p>
                Once you have read through some of the piano basics articles, I would recommend that you check out some of the great <strong><a href="/piano-course-reviews">online piano courses</a></strong> that are available. I have reviewed some of the best programs on the web and want to make sure that your musical learning experience is as easy as possible. With that said, make sure you check back often as I will be steadily uploading new content and finding more options for you as far as online piano lessons go.
                </p>
              </CardText>
            </Card>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    allMdx(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date
            cover
          }
        }
      }
    }
  }
`;
